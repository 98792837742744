<template>
  <div id="issue">
    <van-sticky :offset-top="0">
      <div class="header">
        <van-row gutter="10">
          <van-col span="8" class="header-title" @click="onClickLeft">
            <svg-icon
              :data_iconName="'back-default'"
              :className="'back_icon'"
            />通知管理
          </van-col>
          <van-col v-if="type == '1'" span="13" class="search">
            <input
              type="text"
              class="search-input"
              v-model="query.title"
              @blur="onRefresh"
            />
            <svg-icon
              :data_iconName="'search'"
              :className="'search-input-icon'"
            />
          </van-col>
          <van-col span="3" v-if="type == '1'" @click="onIssueClick">
            <svg-icon :data_iconName="'edit'" :className="'edit-icon'" />
          </van-col>
        </van-row>
        <van-row class="tabs">
          <van-col
            span="12"
            v-for="(item, index) in tabs"
            :key="index"
            :class="[item.type == type ? 'tab-item-active' : 'tab-item']"
            @click="onTabClick(item)"
            >{{ item.text }}</van-col
          >
        </van-row>
      </div>
    </van-sticky>
    <div class="issue-container" v-if="type == '1'">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_notifications"
        >
          <div
            v-for="(item, index) in list"
            :key="index"
            @click="onIssueItemClick(item)"
            :class="[
              item.notification.is_read &&
              item.notification.sender_or_receiver == 'receiver'
                ? 'issue-item-read'
                : 'issue-item',
            ]"
          >
            <van-row>
              <van-col span="14">
                <div :class="[
                    item.notification.is_read &&
                    item.notification.sender_or_receiver == 'receiver'
                      ? 'issue-item-title-read van-ellipsis'
                      : 'issue-item-title van-ellipsis',
                  ]">
                  <svg-icon
                    v-if="item.notification.sender_or_receiver == 'sender'"
                    :data_iconName="'sender'"
                    :className="'sender_icon'"
                  />
                  <svg-icon
                    v-if="item.notification.sender_or_receiver == 'receiver'"
                    :data_iconName="'receiver'"
                    :className="'sender_icon'"
                  />{{
                    item.notification.title
                      ? item.notification.title 
                      : "没有标题"
                  }}
                </div></van-col
              >
              <van-col span="10">
                <div :class="['issue-item-date']">
                  {{ item.notification.created_at }}
                </div>
              </van-col>
            </van-row>
            <div v-if="(!item.notification.is_read)&&(item.notification.notification_type==5)" :class="[item.notification.is_read?'dot background-read':'dot']" ></div>
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <div class="message-container" v-if="type == '2'">
      <van-row style="color: #fff; font-size: 0.3rem; margin-bottom: 0.3rem">
        <van-col span="12"></van-col>

        <van-col span="12" style="text-align: right">
          <van-popover
            v-model="showPopover"
            trigger="click"
            :actions="actions"
            @select="onSelect"
            :offset="[0, 20]"
            placement="bottom"
          >
            <template #reference>
              {{ query1.report_type == "0" ? "日报" : "周报" }}
              <van-icon name="arrow-down" />
            </template>
          </van-popover>
        </van-col>
      </van-row>
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh1">
        <van-list
          v-if="list.length > 0"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="get_reports"
        >
          <div
            class="report-item-container"
            style="margin-bottom: 0.6rem"
            v-for="(item, index) in list"
            :key="index"
            @click="onReportClick(item)"
          >
            <van-row class="report-item">
              <van-col class="issue-item-title" span="8">
                {{ item.report_type_text }}
              </van-col>
              <van-col class="issue-item-date" span="16">
                {{ item.submit_time }}
              </van-col>
            </van-row>
            <van-row class="report-item">
              <van-col
                class="issue-item-title-read van-ellipsis text-left"
                span="12"
              >
                {{
                  `今日带团数${item.lists_count},共计${item.tourists_count}人/次`
                }}
              </van-col>
              <van-col
                class="issue-item-title-read van-ellipsis text-right"
                span="12"
              >
                {{ `紧急情况上报:${item.emergency_reports_count}` }}
              </van-col>
            </van-row>
            <van-row class="report-item">
              <van-col
                class="issue-item-title-read van-ellipsis text-left"
                span="12"
              >
                {{ `上报人:${item.user_name}` }}
              </van-col>
              <van-col
                class="issue-item-title-read van-ellipsis text-right"
                span="12"
              >
                <van-tag
                  plain
                  type="primary"
                  v-if="item.reportable_type == 'TouristOffice'"
                  >旅行社</van-tag
                >
                <van-tag plain type="primary" v-else>导游</van-tag>
              </van-col>
            </van-row>
          </div>
        </van-list>
        <van-empty
          v-if="list.length == 0 && loading == false"
          description="暂无数据"
        />
      </van-pull-refresh>
    </div>
    <Tarbar :active="5"></Tarbar>
  </div>
</template>
<script>
import Tarbar from "../components/Tarbar.vue";
export default {
  name: "issue-list",
  components: { Tarbar },
  data() {
    const _self = this;
    return {
      actions: [
        { text: "日报", report_type: "0" },
        { text: "周报", report_type: "1" },
      ],
      showPopover: false,
      list: [],
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        title: "",
      },
      query1: {
        page: 1,
        report_type: "0",
      },
      type: _self.$route.query.type ? _self.$route.query.type : "1",
      tabs: [
        {
          id: 1,
          text: "系统通知",
          type: "1",
        },
        {
          id: 2,
          text: "日报/周报 ",
          type: "2",
        },
      ],
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    onTabClick(item) {
      if (item.type == "1") {
        this.onRefresh();
      }
      if (item.type == "2") {
        this.onRefresh1();
      }
      this.type = item.type;
    },
    onReportClick(item) {
      this.$router.push({
        path: "/dailyWeekly/item",
        query: {
          id: item.id,
          type: this.type,
        },
      });
    },
    onSelect(item) {
      this.query1.report_type = item.report_type;
      this.onRefresh1();
    },
    async get_notifications() {
      const res = await this.$apis.get_notifications(this.query);
      if (res.next_page) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      this.list = [...this.list, ...res.data];
    },
    async get_reports() {
      const res = await this.$apis.get_reports(this.query1);
      if (res.next_page) {
        this.query1.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }

      this.list = [...this.list, ...res.data];
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_notifications();
    },
    onRefresh1() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query1.page = 1;
      this.loading = true;
      this.get_reports();
    },
    onIssueClick() {
      this.$router.push("/issue");
    },
    onIssueItemClick(item) {
      this.$router.push({
        path: "/issue/item",
        query: { id: item.notification.id, type: this.type },
      });
    },
  },
  created() {
    if (this.type == "1") {
      this.onRefresh();
    }
    if (this.type == "2") {
      this.onRefresh1();
    }
  },
};
</script>
<style scoped>
#issue {
  height: 100vh;
  /* background: #f3f3f3; */
  /* padding-top:10vh; */
}
.header {
  height: 20vh;
  line-height: 10vh;
  padding: 0 0.3rem;
  background: #6c94f6;
  border-bottom-left-radius: 1rem;
}
.header-title {
  color: #fff;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.issue-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: fixed;
  top: 20vh;
  left: 0;
  width: 100vw;
}
.issue-item {
  background: #ffffff;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.issue-item-read {
  background: #f1f1f1;
  height: 1rem;
  box-shadow: 0 0 6px 0 rgba(182, 179, 179, 0.54);
  border-radius: 16px;
  border-radius: 16px;
  line-height: 1rem;
  padding: 0 0.3rem;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  color: #8c92a4;
}
.issue-item-title {
  color: #333;
  font-size: 12px;
  font-weight: bold;
}
.issue-item-title-read {
  color: #8c92a4;
  font-size: 12px;
  font-weight: bold;
}
.text-read {
  color: #8c92a4 !important;
}
.background-read {
  background-color: #8c92a4 !important;
}
.issue-item-body {
  font-size: 14px;
  color: #666666;
  position: absolute;
  top: 0.8rem;
  left: 0.4rem;
}
.issue-item-date {
  font-size: 12px;
  color: #999999;
  text-align: right;
}
.edit-icon {
  font-size: 0.6rem;
}
.dot {
  position: absolute;
  height: 8px;
  width: 8px;
  background: #f04134;
  border-radius: 50%;
  top: 0.2rem;
  right: 0.1rem;
}
.tab-item {
  opacity: 0.47;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}
.tab-item-active {
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  font-weight: bold;
  line-height: 12vh;
}

.message-container {
  padding: 0.4rem;
  height: 90vh;
  overflow-y: scroll;
  padding-bottom: 6rem;
}
.report-item-container {
  background: #fff;
  padding: 0.2rem 0.4rem;
  border-radius: 20px;
  position: relative;
  margin-bottom: 10px;
}
.report-item {
  margin-bottom: 12px;
  /* background: #6c94f6 */
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
</style>